import { Grid, List, ListItemButton, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

export default function NavbarDesktop() {
  const location = useLocation();
  const pathname = location.pathname.split('/')[1].toString();

  return (
    <>
      <Grid container>
        <Grid item xs={2} pl={6} sx={{ zIndex: 1500 }}>
          <img
            src={'https://dev-test-static.obs.ap-southeast-2.myhuaweicloud.com/cdn/images/logo.png'}
            alt="logo"
            width="160"
            style={{ marginTop: '10px' }}
          />
        </Grid>

        <Grid item mt={6}>
          <List
            sx={{
              display: pathname !== 'kline-form' && pathname !== 'kline' && pathname !== 'kbank' ? 'flex' : 'none',
            }}>
            <Link to="/" style={{ textDecoration: 'none', color: '#FFFFFF', textAlign: 'center' }}>
              <ListItemButton key="HOME" id="mainMenuHome" sx={{ minWidth: 100 }}>
                <Typography component="div" variant="body1" sx={{ fontWeight: 700, fontSize: 16 }}>
                  หน้าแรก
                </Typography>
              </ListItemButton>
            </Link>

            <Link to="/services" style={{ textDecoration: 'none', color: '#FFFFFF', minWidth: 100 }}>
              <ListItemButton key="ABOUT" id="mainMenuHome" sx={{ minWidth: 100 }}>
                <Typography component="div" variant="body1" sx={{ fontWeight: 700, fontSize: 16 }}>
                  บริการของเรา
                </Typography>
              </ListItemButton>
            </Link>

            <Link to="/branches-search" style={{ textDecoration: 'none', color: '#FFFFFF', minWidth: 100 }}>
              <ListItemButton key="ABOUT" id="mainMenuHome" sx={{ minWidth: 100 }}>
                <Typography component="div" variant="body1" sx={{ fontWeight: 700, fontSize: 16 }}>
                  สาขาและร้านค้าให้บริการ
                </Typography>
              </ListItemButton>
            </Link>

            <Link to="/announcement" style={{ textDecoration: 'none', color: '#FFFFFF', minWidth: 100 }}>
              <ListItemButton key="ABOUT" id="mainMenuHome" sx={{ minWidth: 100 }}>
                <Typography component="div" variant="body1" sx={{ fontWeight: 700, fontSize: 16 }}>
                  ประกาศที่เกี่ยวข้อง
                </Typography>
              </ListItemButton>
            </Link>

            <Link
              to="/services"
              state={{ faq: true }}
              style={{ textDecoration: 'none', color: '#FFFFFF', minWidth: 100 }}>
              <ListItemButton key="ABOUT" id="mainMenuHome" sx={{ minWidth: 100 }}>
                <Typography component="div" variant="body1" sx={{ fontWeight: 700, fontSize: 16 }}>
                  คำถามที่พบบ่อย
                </Typography>
              </ListItemButton>
            </Link>

            <Link to="/about-us" style={{ textDecoration: 'none', color: '#FFFFFF', minWidth: 100 }}>
              <ListItemButton key="ABOUT" id="mainMenuHome" sx={{ minWidth: 100 }}>
                <Typography component="div" variant="body1" sx={{ fontWeight: 700, fontSize: 16 }}>
                  เกี่ยวกับเรา
                </Typography>
              </ListItemButton>
            </Link>
            <Link to="/contact-us" style={{ textDecoration: 'none', color: '#FFFFFF', minWidth: 100 }}>
              <ListItemButton key="CONTACT" id="mainMenuHome" sx={{ minWidth: 100 }}>
                <Typography component="div" variant="body1" sx={{ fontWeight: 700, fontSize: 16 }}>
                  ติดต่อเรา
                </Typography>
              </ListItemButton>
            </Link>
          </List>
        </Grid>
      </Grid>
    </>
  );
}

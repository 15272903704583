import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import { TypeScreen } from '../../../utils/enum/responsive-enum';
import { linkObsImage } from '../../../contentData/image-obs-link';

export interface Props {
  type: string;
}

const properties = {
  prevArrow: (
    <IconButton sx={{ ml: -6 }}>
      <ArrowBackIos sx={{ fontSize: 50, color: '#263238' }} />
    </IconButton>
  ),
  nextArrow: (
    <IconButton sx={{ mr: -7.5 }}>
      <ArrowForwardIos sx={{ fontSize: 50, color: '#263238' }} />
    </IconButton>
  ),
};

const carousalImageStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  height: '700px',
  backgroundPosition: 'center',
  cursor: 'pointer',
};

const images = [linkObsImage.campaign.slide.guarantor, linkObsImage.campaign.slide.onGuarantor];
export default function CarousalImage(props: Props) {
  const navigate = useNavigate();
  return (
    <>
      {props.type === TypeScreen.DESKTOP && (
        <Box sx={{ minWidth: 650 }}>
          <Slide indicators={true} {...properties}>
            {images.map((slideImage, index) => (
              <>
                <Box
                  id={`id-${index}`}
                  sx={{
                    backgroundImage: `url(${slideImage})`,
                    ...carousalImageStyle,
                  }}
                  onClick={() => {
                    navigate('/services');
                  }}></Box>
              </>
            ))}
          </Slide>
        </Box>
      )}

      {props.type !== TypeScreen.DESKTOP && (
        <Box className="slide-container">
          <Slide indicators={true}>
            {images.map((slideImage, index) => (
              <Box key={index} sx={{ margin: 'auto' }}>
                <img
                  src={slideImage}
                  width="100%"
                  style={{
                    borderRadius: 4,
                  }}
                />
              </Box>
            ))}
          </Slide>
        </Box>
      )}
    </>
  );
}

import { linkObsImage } from '../../contentData/image-obs-link';

export const content = {
  skuContent: {
    price: 'ราคา',
    down: 'ดาวน์',
    downText: 'เริ่มต้นเพียง',
    installmentsText: 'ผ่อนงวดละ',
    installmentsTerm: 'x6 งวด',
    screenSize: 'ขนาดจอ',
    screen: 'จอ',
    interrestRate0: 'ดอกเบี้ย 0% ต่อปี',
    hypothesis: '*กู้เท่าที่จำเป็นและชำระคืนไหว',
  },
};

export const samsungGalaxyA05 = {
  title: 'Samsung Galaxy A05 4G',
  urlImage: linkObsImage.service.mobiles.A05,
  imageHeight: 300,
  imageWidth: 365,
  downPrice: '1,329.-',
  installmentPrice: '790.-',
  screenSize: '6.7 นิ้ว',
  screen: 'TFT-LCD 24-bit \n 730 x 1600 พิกเซล',
  camera: '50 MP + 2 MP (Depth) \n กล้องหน้า 8 MP',
  memory: 'RAM 4 GB \n ROM 128 GB',
  bettery: '5,000 mAh \n ชาร์จไว 25 W',
  alignText: 'left',
  paddingLeftText: '18%',
  installmentTerm: 'x6 งวด',
  hypothesis:
    '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 6,069 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 6 เดือน)) โดนมีงวดดาวน์งวดแรกจำนวน 1,329 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 790 บาทต่อเดือน ผ่อนนาน 6 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
  price: '6,069.-',
};

export const samsungGalaxyA05s = {
  title: 'Samsung Galaxy A05s 4G',
  urlImage: linkObsImage.service.mobiles.A05s,
  imageHeight: 480,
  imageWidth: 365,
  downPrice: '1,719.-',
  installmentPrice: '1,000.-',
  screenSize: '6.7 นิ้ว',
  screen: 'TFT-LCD 24-bit \n 1080 x 2400 พิกเซล',
  camera: '50 MP + 2 MP (Macro) + 2 MP (Depth) กล้องหน้า 13 MP',
  memory: 'RAM 6 GB \n ROM 128 GB',
  bettery: '5,000 mAh \n ชาร์จไว 25 W',
  alignText: 'left',
  paddingLeftText: '18%',
  isAppliance: false,
  installmentTerm: 'x6 งวด',
  hypothesis:
    '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 7,719 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 6 เดือน)) โดนมีงวดดาวน์งวดแรกจำนวน 1,719 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 1,000 บาทต่อเดือน ผ่อนนาน 6 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
  price: '7,719.-',
};
export const samsungGalaxyA244G = {
  title: 'Samsung Galaxy A24 4G',
  urlImage: linkObsImage.service.mobiles.A24,
  imageHeight: 480,
  imageWidth: 365,
  downPrice: '2,439.-',
  installmentPrice: '1,460.-',
  screenSize: '6.5 นิ้ว',
  screen: 'Super AMOLED 24-bit \n 1080 x 2340 พิกเซล',
  camera: '50 MP + 5 MP (Ultrawide) + 2 MP (Macro) กล้องหน้า 13 MP',
  memory: 'RAM 6 GB \n ROM 128 GB',
  bettery: '5,000 mAh \n ชาร์จไว 25 W',
  alignText: 'left',
  paddingLeftText: '18%',
  isAppliance: false,
  installmentTerm: 'x6 งวด',
  hypothesis:
    '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 11,199 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 6 เดือน)) โดนมีงวดดาวน์งวดแรกจำนวน 2,439 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 1,460 บาทต่อเดือน ผ่อนนาน 6 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
  price: '11,199.-',
};

export const samsungGalaxyA345G = {
  title: 'Samsung Galaxy A34 5G',
  urlImage: linkObsImage.service.mobiles.A34,
  imageHeight: 480,
  imageWidth: 365,
  downPrice: '3,659.-',
  installmentPrice: '2,190.-',
  screenSize: '6.6 นิ้ว',
  screen: 'Super AMOLED 24-bit \n 1080 x 2340 พิกเซล',
  camera: '48 MP + 8 MP (Ultrawide) + 5 MP (Macro) กล้องหน้า 13 MP',
  memory: 'RAM 8 GB \n ROM 128 GB',
  bettery: '5,000 mAh \n ชาร์จไว 25 W',
  alignText: 'left',
  paddingLeftText: '18%',
  isAppliance: false,
  installmentTerm: 'x6 งวด',
  hypothesis:
    '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 16,799 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 6 เดือน)) โดนมีงวดดาวน์งวดแรกจำนวน 3,659 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 2,190 บาทต่อเดือน ผ่อนนาน 6 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
  price: '16,799.-',
};

export const oppoA184G = {
  title: 'Oppo A18 4G',
  urlImage: linkObsImage.service.mobiles.A18,
  imageHeight: 480,
  imageWidth: 365,
  downPrice: '1,669.-',
  installmentPrice: '780.-',
  screenSize: '6.56 นิ้ว',
  screen: 'IPS-LCD 24-bit \n 720 x 1612 พิกเซล',
  camera: '8 MP + 2 MP (Depth) กล้องหน้า 5 MP',
  memory: 'RAM 4 GB \n ROM 128 GB',
  bettery: '5,000 mAh',
  alignText: 'left',
  paddingLeftText: '18%',
  isAppliance: false,
  installmentTerm: 'x6 งวด',
  hypothesis:
    '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 6,349 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 6 เดือน)) โดนมีงวดดาวน์งวดแรกจำนวน 1,669 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 780 บาทต่อเดือน ผ่อนนาน 6 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
  price: '6,349.-',
};

export const oppoA584G = {
  title: 'Oppo A58 4G',
  urlImage: linkObsImage.service.mobiles.A58,
  imageHeight: 480,
  imageWidth: 365,
  downPrice: '2,389.-',
  installmentPrice: '1,080.-',
  screenSize: '6.72 นิ้ว',
  screen: 'LCD 24-bit \n 1080 x 2400 พิกเซล',
  camera: '50 MP + 2 MP (Depth) กล้องหน้า 8 MP',
  memory: 'RAM 6 GB \n ROM 128 GB',
  bettery: '5,000 mAh \n ชาร์จไว 33 W',
  alignText: 'left',
  paddingLeftText: '18%',
  isAppliance: false,
  installmentTerm: 'x6 งวด',
  hypothesis:
    '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 8,869 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 6 เดือน)) โดนมีงวดดาวน์งวดแรกจำนวน 2,389 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 1,080 บาทต่อเดือน ผ่อนนาน 6 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
  price: '8,869.-',
};

export const oppoA784G = {
  title: 'Oppo A78 4G',
  urlImage: linkObsImage.service.mobiles.A78,
  imageHeight: 480,
  imageWidth: 365,
  downPrice: '3,329.-',
  installmentPrice: '1,550.-',
  screenSize: '6.43 นิ้ว',
  screen: 'AMOLED 24-bit \n 1080 x 2400 พิกเซล',
  camera: '50 MP + 2 MP (Depth) กล้องหน้า 8 MP',
  memory: 'RAM 8 GB \n ROM 256 GB',
  bettery: '5,000 mAh \n ชาร์จไว 67 W',
  alignText: 'left',
  paddingLeftText: '18%',
  isAppliance: false,
  installmentTerm: 'x6 งวด',
  hypothesis:
    '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 12,629 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 6 เดือน)) โดนมีงวดดาวน์งวดแรกจำนวน 3,329 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 1,550 บาทต่อเดือน ผ่อนนาน 6 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
  price: '12,629.-',
};

export const oppoA985G = {
  title: 'Oppo A98 5G',
  urlImage: linkObsImage.service.mobiles.A98,
  imageHeight: 480,
  imageWidth: 365,
  downPrice: '4,079.-',
  installmentPrice: '1,890.-',
  screenSize: '6.72 นิ้ว',
  screen: 'LTPS IPS LCD 24-bit \n\t 1080 x 2400 พิกเซล',
  camera: '64 MP + 2 MP (Microscope) + 2 MP (Depth) กล้องหน้า 32 MP',
  memory: 'RAM 8 GB \n ROM 256 GB',
  bettery: '5,000 mAh \n ชาร์จไว Li-Pol 67 W',
  alignText: 'left',
  paddingLeftText: '18%',
  isAppliance: false,
  installmentTerm: 'x6 งวด',
  hypothesis:
    '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 15,419 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 6 เดือน)) โดนมีงวดดาวน์งวดแรกจำนวน 4,079 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 1,890 บาทต่อเดือน ผ่อนนาน 6 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
  price: '15,419.-',
};

export const oppoA3Pro5G = {
  title: 'Oppo A3 Pro 5G',
  urlImage: linkObsImage.service.mobiles.A3Pro,
  imageHeight: 480,
  imageWidth: 365,
  downPrice: '3,279.-',
  installmentPrice: '1,320.-',
  screenSize: '6.67 นิ้ว',
  screen: 'LCD 1604 x 720 พิกเซล',
  camera: 'กล้องหน้า 8 MP + กล้องหลัง 50 MP + 2MP ',
  memory: 'RAM 6 GB \n ROM 128 GB',
  bettery: '5,000 mAh \n ชาร์จไว 45 W',
  alignText: 'left',
  paddingLeftText: '18%',
  isAppliance: false,
  installmentTerm: 'x6 งวด',
  hypothesis:
    '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 11,199 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 6 เดือน)) โดนมีงวดดาวน์งวดแรกจำนวน 3,279 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 1,320 บาทต่อเดือน ผ่อนนาน 6 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
  price: '11,199.-',
};
export const oppoA3x = {
  title: 'Oppo A3x 4G',
  urlImage: linkObsImage.service.mobiles.A3X,
  imageHeight: 480,
  imageWidth: 365,
  downPrice: '1,899.-',
  installmentPrice: '780.-',
  screenSize: '6.72 นิ้ว',
  screen: 'LCD 1604 x 720 พิกเซล',
  camera: 'กล้องหน้า 5 MP (f/2.2) กล้องหลัง 2MP + 8MP (f/2.0)',
  memory: 'RAM 4 GB \n ROM 128 GB',
  bettery: '5,000 mAh \n ชาร์จไว 45 W',
  alignText: 'left',
  paddingLeftText: '18%',
  isAppliance: false,
  installmentTerm: 'x6 งวด',
  hypothesis:
    '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 6,579 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 6 เดือน)) โดนมีงวดดาวน์งวดแรกจำนวน 1,899 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 780 บาทต่อเดือน ผ่อนนาน 6 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
  price: '6,579.-',
};

//------------------appliance------------------------
export const daikinMaxInverter = {
  title: 'เครื่องใช้ไฟฟ้า Daikin',
  urlImage: linkObsImage.service.appliance.airConditioningDaikin,
  imageHeight: 300,
  imageWidth: '100%',
  alignText: 'left',
  paddingLeftText: '55%',
  marginTopTextDesktop: -6,
  marginRightTextDesktop: 10,
  marginTopTextMobile: 12,
  downPrice: '4,019.-',
  installmentPrice: '1,960.-',
  installmentTerm: 'x12 งวด',
  skuName: 'Max Inverter',
  skuModel: 'FTKQ12XV2S/FTKQ18XV2S',
  specVal1: 'ขนาด BTU:',
  specVal2: '12,300 และ 18,100 BTU',
  features1: {
    icon: linkObsImage.service.icon.powerful,
    title: 'Powerful mode',
    description: 'ลมเย็นอย่างรวดเร็วภายใน 1 นาที',
  },
  features2: {
    icon: linkObsImage.service.icon.selfhygiene,
    title: 'Self-hygiene',
    description: 'โหมดทำความสะอาดคอยล์เย็น ลดการสะสมสิ่งสกปรกและเชื้อโรค',
  },
  features3: {
    icon: linkObsImage.service.icon.quite,
    title: 'Quite operation mode',
    description: 'ทำงานเงียบเสียงเบา และเงียบเพียง 40 dB(A)',
  },
  features4: {
    icon: linkObsImage.service.icon.airFilter,
    title: 'Air quality mode',
    description: 'ช่วยกรองฝุ่น PM 2.5 และช่วยยับยั้งแบคทีเรียด้วยสารชีวภาพ Enzyme Blue',
  },
  remark:
    '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 27,539 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 12 เดือน)) โดยมีงวดดาวน์งวดแรกจำนวน 4,019 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 1,960 บาทต่อเดือน ผ่อนนาน 12 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
  isAppliance: true,
  isAircondition: true,
  price: '27,539.-',
};

export const tclNewMiracle = {
  title: 'เครื่องใช้ไฟฟ้า TCL',
  urlImage: linkObsImage.service.appliance.airConditioningTcl,
  imageHeight: 300,
  imageWidth: '100%',
  alignText: 'left',
  paddingLeftText: '55%',
  marginTopTextDesktop: -6,
  marginRightTextDesktop: 10,
  marginTopTextMobile: 12,
  downPrice: '2,999.-',
  installmentPrice: '1,320.-',
  installmentTerm: 'x12 งวด',
  skuName: 'New Miracle',
  skuModel: 'TAC-MFS13',
  specVal1: 'ขนาด BTU:',
  specVal2: '12,520 BTU',
  features1: {
    icon: linkObsImage.service.icon.filter,
    title: 'Filter Cleaning Reminder',
    description: 'การเตือนทำความสะอาดแผ่นกรองอัตโนมัติ',
  },
  features2: {
    icon: linkObsImage.service.icon.airFlow,
    title: 'Smart Air Flow',
    description: 'ใบพัดกระจายลมเย็นขยับ ขึ้น-ลง และ ซ้าย-ขวา อัตโนมัติ',
  },
  features3: {
    icon: linkObsImage.service.icon.evaporator,
    val1: 'Titan Gold',
    description: 'เคลือบคอยล์ร้อนและคอยล์เย็น สิทธิบัตรเฉพาะทีซีแอล',
  },
  features4: {
    icon: linkObsImage.service.icon.warranty,
    title: 'Warranty',
    description: 'รับประกันยาวนานคอมเพรสเซอร์รับประกัน 10 ปี อะไหล่ทั่วไปรับประกัน 5 ปี',
  },
  remark:
    '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 18,839 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 12 เดือน)) โดยมีงวดดาวน์งวดแรกจำนวน 2,999 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 1,320 บาทต่อเดือน ผ่อนนาน 12 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
  isAppliance: true,
  isAircondition: true,
  price: '18,839.-',
};

export const toshibaTopload = {
  title: 'เครื่องใช้ไฟฟ้า Toshiba',
  urlImage: linkObsImage.service.appliance.washingMachineToshiba,
  imageHeight: 420,
  imageWidth: '100%',
  alignText: 'center',
  paddingLeftText: '0',
  marginTopTextDesktop: 2,
  marginRightTextDesktop: 0,
  marginTopTextMobile: 6,
  downPrice: '1,899.-',
  installmentPrice: '1,100.-',
  installmentTerm: 'x6 งวด',
  skuName: 'Top load 8 kg.',
  skuModel: 'AW-M901BT-WW',
  specVal1: 'ขนาดกิโลกรัม:',
  specVal2: '8 กิโลกรัม',
  features1: {
    icon: linkObsImage.service.icon.laundry,
    title: 'Soft Close Lid',
    description: 'ระหว่างการเปิด-ปิดฝาเครื่องใช้งานฝาจะปิดโดยการลดลงอย่างช้าๆ และปลอดภัย ไม่กระแทก',
  },
  features2: {
    icon: linkObsImage.service.icon.dryingMachine,
    title: 'พลังน้ำแรงสูง',
    description: 'ช่วยขจัดสิ่งสกปรกตกค้างในถังซัก ทั้งภายในและภายนอก',
  },
  features3: {
    icon: linkObsImage.service.icon.quickDry,
    title: 'ประหยัดน้ำ',
    description: 'คำนวณปริมาณน้ำอัตโนมัติตามน้ำหนักผ้าที่ซัก',
  },
  features4: {
    icon: linkObsImage.service.icon.backTime,
    title: 'ประหยัดเวลา',
    description: 'ด้วยโปรแกรมร่นเวลาซัก สำหรับผ้าน้อยชิ้น',
  },
  remark:
    '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 8,499 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 6 เดือน)) โดยมีงวดดาวน์งวดแรกจำนวน 1,899 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 1,100 บาทต่อเดือน ผ่อนนาน 6 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
  isAppliance: true,
  isAircondition: false,
  price: '8,499.-',
};

export const mitsubishiFlatDesign = {
  title: 'เครื่องใช้ไฟฟ้า MITSUBISHI',
  urlImage: linkObsImage.service.appliance.refrigeratorMitsu,
  imageHeight: 420,
  imageWidth: '100%',
  alignText: 'center',
  paddingLeftText: '0',
  marginTopTextDesktop: 2,
  marginRightTextDesktop: 0,
  marginTopTextMobile: 6,
  downPrice: '2,129.-',
  installmentPrice: '1,510.-',
  installmentTerm: 'x6 งวด',
  skuName: 'Flat Design',
  skuModel: 'MR-FV22T-SL/MR-FV22T-BR',
  specVal1: 'ขนาดความจุ:',
  specVal2: '7.3 คิว',
  features1: {
    icon: linkObsImage.service.icon.processControl,
    title: '',
    description: 'ระบบปฎิบัติควบคุมการทำงาน สั่งการตรวจจับอุณหภูมิในจุดต่างๆ',
  },
  features2: {
    icon: linkObsImage.service.icon.antiVirus,
    title: '',
    description: 'ผนังช่องแช่ ผสมสารช่วยยับยั้งการเติบโตของเชื้อแบคทีเรียได้',
  },
  features3: {
    icon: linkObsImage.service.icon.cool,
    title: '',
    description: 'กระจายความเย็น ส่งความเย็นสู่ด้านหน้าประตู ได้เย็นเร็วทันใจ',
  },
  features4: {
    icon: linkObsImage.service.icon.mitsuWaranty,
    title: '',
    description: 'รับประกันยาวนาน คอมเพสเซอร์รับประกัน 10 ปี อะไหล่ภายในตัวเครื่องรับประกัน 2 ปี',
  },
  remark:
    '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 11,189 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 6 เดือน)) โดยมีงวดดาวน์งวดแรกจำนวน 2,129 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 1,510 บาทต่อเดือน ผ่อนนาน 6 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
  isAppliance: true,
  isAircondition: false,
  price: '11,189.-',
};

export const vivoY18 = {
  title: 'Vivo Y18 LTE',
  urlImage: linkObsImage.service.mobiles.Y18,
  imageHeight: 480,
  imageWidth: 365,
  downPrice: '2,119.-',
  installmentPrice: '930.-',
  screenSize: '6.56 นิ้ว',
  screen: 'IPS LCD 24-bit \n 720 x 1612 พิกเซล',
  camera: '50 MP + 0.08MP (Auxiliary lens) \n กล้องหน้า 8 MP',
  memory: 'RAM 8 GB \n ROM 128 GB',
  bettery: '5,000 mAh \n ชาร์จไว 15 W',
  alignText: 'left',
  paddingLeftText: '18%',
  isAppliance: false,
  installmentTerm: 'x6 งวด',
  hypothesis:
    '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 7,699 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 6 เดือน)) โดยมีงวดดาวน์งวดแรกจำนวน 2,119 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 930 บาทต่อเดือน ผ่อนนาน 6 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
  price: '7,699.-',
};

import { Grid } from '@mui/material';
import { TypeScreen } from '../../../utils/enum/responsive-enum';
import { linkObsImage } from '../../../contentData/image-obs-link';

export interface Props {
  type: string;
}

export default function StepContent(props: Props) {
  const imgDesktopStyle = {
    maxWidth: 200,
    marginRight: '2.3em',
    ...(props.type === TypeScreen.MOBILE && {
      maxWidth: '100%',
      margin: '0 5% 5% 0',
    }),
  };

  return (
    <>
      <Grid container mt={2}>
        <Grid xs={props.type === TypeScreen.MOBILE ? 6 : 2.4} pr={2}>
          <img src={linkObsImage.campaign.step.step1} width="100%" style={imgDesktopStyle} />
        </Grid>
        <Grid xs={props.type === TypeScreen.MOBILE ? 6 : 2.4} pr={2}>
          <img src={linkObsImage.campaign.step.step2} width="100%" style={imgDesktopStyle} />
        </Grid>
        <Grid xs={props.type === TypeScreen.MOBILE ? 6 : 2.4} pr={2}>
          <img src={linkObsImage.campaign.step.step3} width="100%" style={imgDesktopStyle} />
        </Grid>
        <Grid xs={props.type === TypeScreen.MOBILE ? 6 : 2.4} pr={2}>
          <img src={linkObsImage.campaign.step.step4} width="100%" style={imgDesktopStyle} />
        </Grid>
        <Grid xs={props.type === TypeScreen.MOBILE ? 6 : 2.4} pr={2}>
          <img src={linkObsImage.campaign.step.step5} width="100%" style={imgDesktopStyle} />
        </Grid>
      </Grid>
    </>
  );
}

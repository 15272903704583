import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { platform } from '../../utils/enum/search-branches-enum';
import AccordionText from '../commons/ui/accordion-text';

export default function FAQContent() {
  const navigate = useNavigate();

  return (
    <>
      <Box mb={2}>
        <AccordionText id="panel1" defaultExpanded="panel1" headerText="สินเชื่อ kbao คืออะไร">
          <Typography>
            สินเชื่อ kbao (เคบาว) คือ สินเชื่อเพื่อซื้อโทรศัพท์มือถือ และเครื่องใช้ไฟฟ้า
            ที่สามารถยื่นขอสมัครสินเชื่อได้ด้วยบัตรประชาชนใบเดียว และทราบผลการอนุมัติทันที
          </Typography>
        </AccordionText>
      </Box>

      <Box mb={2}>
        <AccordionText
          id="panel2"
          defaultExpanded="panel1"
          headerText="เอกสารประกอบการสมัครและเงื่อนไขในการสมัครสินเชื่อคืออะไร">
          <Box>
            <ol>
              <li>เอกสารประกอบการสมัครสินเชื่อ ใช้เพียงบัตรประชาชนใบเดียวในการสมัคร</li>
              <li>
                เงื่อนไขในการสมัครสินเชื่อ สำหรับผู้กู้ แลผู้ค้ำประกัน (ถ้ามี) ได้แก่
                <ul>
                  <li>ต้องเป็นบุคคลธรรมดาสัญชาติไทย อายุระหว่าง 20-75 ปี</li>
                  <li>มีถิ่นพำนักเป็นหลักแหล่งในประเทศไทย</li>
                  <li>
                    ผู้กู้ และผู้ค้ำ (ถ้ามี) ต้องมีเบอร์โทรศัพท์เป็นของตนเองเพื่อลงทะเบียนและยืนยันตัวตน ผ่าน (One Time
                    Password – OTP)
                  </li>
                  <li>จำกัด 1 บัตรประชาชนต่อ 1 สินเชื่อ</li>
                </ul>
              </li>
            </ol>
          </Box>
        </AccordionText>
      </Box>

      <Box mb={2}>
        <AccordionText id="panel3" defaultExpanded="panel1" headerText="สมัครสินเชื่อได้ที่ไหน">
          <Typography>
            สามารถสมัครสินเชื่อผ่านช่องทางร้านค้า ซีเจ มอร์ (ดูรายชื่อสาขาที่ร่วมรายการได้โดยคลิก
            <b
              onClick={() => {
                navigate('/branches-search');
              }}
              style={{ cursor: 'pointer', color: '#0420bb' }}>
              ที่นี่
            </b>
            )
          </Typography>
        </AccordionText>
      </Box>

      <Box mb={2}>
        <AccordionText id="panel4" defaultExpanded="panel1" headerText="มีสินค้าประเภทใดบ้างที่เข้าร่วมโครงการ">
          <Typography>มี 2 ประเภทสินค้า ได้แก่</Typography>
          <ul>
            <li>โทรศัพท์มือถือหลายยี่ห้อ เช่น ซัมซุง (Samsung) ออปโป้ (OPPO) และอื่น ๆ</li>
            <li>เครื่องใช้ไฟฟ้าขนาดใหญ่ ได้แก่ เครื่องปรับอากาศ ตู้เย็น และเครื่องซักผ้า</li>
          </ul>
        </AccordionText>
      </Box>

      <Box mb={2}>
        <AccordionText
          id="panel5"
          defaultExpanded="panel1"
          headerText="ระยะเวลาการผ่อนชำระ และวิธีการชำระเงินคืน เป็นอย่างไร">
          <Typography>
            หลังจากได้รับการอนุมัติสินเชื่อ ลูกค้าจะต้องชำระเงินดาวน์ ณ วันที่ทำสัญญา
            และชำระค่างวดที่เหลือตามประเภทสินค้าในตารางด้านล่างนี้
          </Typography>

          <Box mt={2}>
            <table
              style={{ margin: 'auto', border: '1px solid #000000', textAlign: 'center', borderCollapse: 'collapse' }}>
              <tr>
                <td style={{ borderRight: '1px solid #000000', borderBottom: '1px solid #000000', padding: '10px' }}>
                  <b>ประเภทสินค้า</b>
                </td>
                <td style={{ borderBottom: '1px solid #000000', padding: '0 10px 0 10px' }}>
                  <b>จำนวนงวดในการผ่อนชำระ</b>
                </td>
              </tr>
              <tr>
                <td style={{ borderRight: '1px solid #000000', borderBottom: '1px solid #000000', padding: '10px' }}>
                  โทรศัพท์มือถือ
                </td>
                <td style={{ borderBottom: '1px solid #000000' }}>6 งวด</td>
              </tr>
              <tr>
                <td style={{ borderRight: '1px solid #000000', borderBottom: '1px solid #000000', padding: '10px' }}>
                  ตู้เย็น และเครื่องซักผ้า
                </td>
                <td style={{ borderBottom: '1px solid #000000' }}>6 งวด</td>
              </tr>
              <tr>
                <td style={{ borderRight: '1px solid #000000', padding: '10px' }}>เครื่องปรับอากาศ</td>
                <td>12 งวด</td>
              </tr>
            </table>
          </Box>
        </AccordionText>
      </Box>

      <Box mb={2}>
        <AccordionText
          id="panel6"
          defaultExpanded="panel1"
          headerText="มีช่องทางใดบ้างในการชำระค่างวด และกำหนดนัดหมายในการชำระค่างวดเป็นอย่างไร">
          <ol>
            <li>
              ช่องทางชำระค่างวด สามารถชำระได้ผ่าน 3 ช่องทาง ได้แก่
              <ul>
                <li>
                  ชำระที่ร้านค้าซีเจ มอร์ (ดูรายชื่อสาขาที่ร่วมรายการได้โดยคลิก
                  <b
                    onClick={() => {
                      navigate('/branches-search', { state: { platform: platform.CJ } });
                    }}
                    style={{ cursor: 'pointer', color: '#0420bb' }}>
                    ที่นี่
                  </b>
                  )
                </li>
                <li>ชำระผ่านแอปพลิเคชั่นธนาคาร</li>
                <li>ชำระผ่านตู้เอทีเอ็มธนาคาร</li>
              </ul>
              <Typography mb={2}>
                หลังชำระค่างวดเรียบร้อยแล้ว ลูกค้าจะได้รับข้อความยืนยันการชำระเงินผ่านช่องทาง SMS
                ตามเบอร์โทรศัพท์ที่ลูกค้าได้แจ้งไว้ตอนสมัครใช้บริการ ภายใน 3 วันทำการ
              </Typography>
            </li>
            <li>
              กำหนดชำระค่างวด
              <Typography>
                ลูกค้าจะได้รับใบแจ้งยอดสินเชื่อในเดือนถัดไปหลังจากทำสัญญาผ่านช่องทาง SMS
                ตามเบอร์โทรศัพท์ที่ลูกค้าได้แจ้งไว้ตอนสมัครใช้บริการ และต้องชำระค่างวดภายในวันสุดท้ายของเดือนนั้น ๆ
              </Typography>
            </li>
          </ol>
        </AccordionText>
      </Box>

      <Box mb={2}>
        <AccordionText
          id="panel7"
          defaultExpanded="panel1"
          headerText="กรณีที่มีเงินก้อน ลูกค้าสามารถชำระสินเชื่อที่เหลือทั้งหมด เพื่อปิดสินเชื่อได้หรือไม่">
          <Typography>
            สามารถทำได้ โดยแจ้งความประสงค์มายังบริษัทผ่านช่องทางแอปพลิเคชั่นไลน์ (LINE) ที่ @kbao
            โดยไม่มีค่าธรรมเนียมและค่าปรับใด ๆ เพิ่มเติม
          </Typography>
        </AccordionText>
      </Box>

      <Box mb={2}>
        <AccordionText
          id="panel8"
          defaultExpanded="panel1"
          headerText="หากลูกค้าชำระยอดสินเชื่อเกินกว่ากำหนด จะมีค่าปรับหรือค่าใช้จ่ายเพิ่มเติม หรือไม่">
          <Typography>
            กรณีที่ลูกค้าผิดนัดชำระหรือชำระไม่ครบตามยอดที่ต้องชำระ ทางบริษัทจะคิดค่าทวงถามหนี้ ดอกเบี้ยผิดนัด
            และค่าใช้จ่ายเพิ่มเติม ตามที่กำหนดในสัญญา
          </Typography>
        </AccordionText>
      </Box>

      <Box mb={2}>
        <AccordionText
          id="panel9"
          defaultExpanded="panel1"
          headerText="ในกรณีที่ลูกค้าสมัครสินเชื่อแล้ว ลูกค้าสามารถยกเลิกสินเชื่อได้หรือไม่">
          <Typography>มี 2 กรณี คือ</Typography>
          <ol>
            <li>
              ในกรณีที่การสมัครสินเชื่อเสร็จสมบูรณ์ หมายความว่า ลูกค้าได้ชำระเงินดาวน์และรับสินค้าไปแล้ว
              ลูกค้าจะไม่สามารถทำการยกเลิกสินเชื่อได้
            </li>
            <li>
              ในกรณีที่การสมัครยังไม่เสร็จสมบูรณ์ เช่น ยังไม่ได้ชำระเงินดาวน์ และยังไม่ได้รับสินค้า
              ลูกค้าสามารถทำการยกเลิกสินเชื่อได้ โดยแจ้งที่พนักงานของร้านซีเจ มอร์ ที่ทำการสมัคร (ทั้งนี้
              ในกรณีที่ลูกค้ามีความสนใจที่จะสมัครสินเชื่อใหม่ สามารถสมัครได้หลังจาก 3 วัน นับแต่วันที่แจ้งยกเลิก)
            </li>
          </ol>
        </AccordionText>
      </Box>

      <Box mb={2}>
        <AccordionText
          id="panel10"
          defaultExpanded="panel1"
          headerText="ในกรณีที่ลูกค้าต้องการความช่วยเหลือด้านการชำระหนี้ หรือกรณีที่ลูกค้าไม่สามารถชำระหนี้ได้ตามปกติ">
          <Typography>
            ลูกค้าสามารถติดต่อเพื่อขอความช่วยเหลือได้ที่ ฝ่ายบริหารจัดการหนี้สิน โทร 02-082-8000 กด 99
            โดยไม่มีค่าใช้จ่ายใด ๆ เพิ่มเติม ทั้งนี้ เกณฑ์การพิจารณาให้ความช่วยเหลือเป็นไปตามที่บริษัทกำหนด
          </Typography>
        </AccordionText>
      </Box>
    </>
  );
}

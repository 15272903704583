import { Box } from '@mui/material';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { linkObsImage } from '../../../contentData/image-obs-link';

const glassPanel = {
  margin: '5% auto',
};

const images = [linkObsImage.home.mbanner1, linkObsImage.home.mbanner2, linkObsImage.home.mbanner3];
export default function CarousalImageMobile() {
  return (
    <>
      <Box className="slide-container">
        <Slide>
          {images.map((slideImage, index) => (
            <Box key={index} style={glassPanel}>
              <img
                src={slideImage}
                width="100%"
                style={{
                  borderRadius: 4,
                }}
              />
            </Box>
          ))}
        </Slide>
      </Box>
    </>
  );
}

import { linkObsImage } from './image-obs-link';

export const content = {
  header: 'บริการของเรา',
  title: 'ผ่อนโทรศัพท์มือถือและเครื่องใช้ไฟฟ้ากับ kbao ผ่าน CJ A-Home สาขาที่ร่วมรายการได้แล้ววันนี้',
  text: 'ข้าพเจ้าตกลงให้บริษัท กสิกรไทย คาราบาว จำกัด \n (“บริษัท”)ใช้ข้อมูลข้าง',

  homepage: {
    lineBK: {
      title: 'พิเศษสำหรับลูกค้า KBank',
      button: {
        text: 'สอบถามเพิ่มเติม',
        idLine: 'LINE @kbao',
        link: 'https://lin.ee/8NUQ2hZ',
        branch: 'ซื้อได้วันนี้ที่ CJ',
      },
    },
    bestSelling: {
      title: 'สินค้าขายดี',
      more: 'ดูเพิ่มเติม',
    },
    step: {
      title: 'ขั้นตอนการซื้อ',
    },
    faq: {
      title: 'คำถามที่พบบ่อย',
    },
  },
  skuContent: {
    price: 'ราคา',
    down: 'ดาวน์',
    downText: 'เริ่มต้นเพียง',
    installmentsText: 'ผ่อนงวดละ',
    installmentsTerm: 'x6 งวด',
    screenSize: 'ขนาดจอ',
    screen: 'จอ',
    interrestRate0: 'ดอกเบี้ย 0% ต่อปี',
    hypothesis: '*กู้เท่าที่จำเป็นและชำระคืนไหว',
  },
};

export const slideProducts = [
  {
    title: 'Samsung Galaxy A05 4G',
    urlImage: linkObsImage.service.mobiles.A05,
    imageHeight: 300,
    imageWidth: 365,
    downPrice: '1,329.-',
    installmentPrice: '790.-',
    screenSize: '6.7 นิ้ว',
    screen: 'TFT-LCD 24-bit \n 730 x 1600 พิกเซล',
    camera: '50 MP + 2 MP (Depth) \n กล้องหน้า 8 MP',
    memory: 'RAM 4 GB \n ROM 128 GB',
    bettery: '5,000 mAh \n ชาร์จไว 25 W',
    alignText: 'left',
    paddingLeftText: '18%',
    installmentTerm: 6,
    brand: 'samsung',
    hypothesis:
      '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 6,069 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 6 เดือน)) โดนมีงวดดาวน์งวดแรกจำนวน 1,329 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 790 บาทต่อเดือน ผ่อนนาน 6 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
    price: '6,069.-',
  },
  {
    title: 'Samsung Galaxy A05s 4G',
    urlImage: linkObsImage.service.mobiles.A05s,
    imageHeight: 480,
    imageWidth: 365,
    downPrice: '1,719.-',
    installmentPrice: '1,000.-',
    screenSize: '6.7 นิ้ว',
    screen: 'TFT-LCD 24-bit \n 1080 x 2400 พิกเซล',
    camera: '50 MP + 2 MP (Macro) + 2 MP (Depth) กล้องหน้า 13 MP',
    memory: 'RAM 6 GB \n ROM 128 GB',
    bettery: '5,000 mAh \n ชาร์จไว 25 W',
    alignText: 'left',
    paddingLeftText: '18%',
    isAppliance: false,
    installmentTerm: 6,
    brand: 'samsung',
    hypothesis:
      '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 7,719 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 6 เดือน)) โดนมีงวดดาวน์งวดแรกจำนวน 1,719 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 1,000 บาทต่อเดือน ผ่อนนาน 6 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
    price: '7,719.-',
  },
  {
    title: 'Samsung Galaxy A24 4G',
    urlImage: linkObsImage.service.mobiles.A24,
    imageHeight: 480,
    imageWidth: 365,
    downPrice: '2,439.-',
    installmentPrice: '1,460.-',
    screenSize: '6.5 นิ้ว',
    screen: 'Super AMOLED 24-bit \n 1080 x 2340 พิกเซล',
    camera: '50 MP + 5 MP (Ultrawide) + 2 MP (Macro) กล้องหน้า 13 MP',
    memory: 'RAM 6 GB \n ROM 128 GB',
    bettery: '5,000 mAh \n ชาร์จไว 25 W',
    alignText: 'left',
    paddingLeftText: '18%',
    isAppliance: false,
    installmentTerm: 6,
    brand: 'samsung',
    hypothesis:
      '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 11,199 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 6 เดือน)) โดนมีงวดดาวน์งวดแรกจำนวน 2,439 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 1,460 บาทต่อเดือน ผ่อนนาน 6 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
    price: '11,199.-',
  },
  {
    title: 'Samsung Galaxy A34 5G',
    urlImage: linkObsImage.service.mobiles.A34,
    imageHeight: 480,
    imageWidth: 365,
    downPrice: '3,659.-',
    installmentPrice: '2,190.-',
    screenSize: '6.6 นิ้ว',
    screen: 'Super AMOLED 24-bit \n 1080 x 2340 พิกเซล',
    camera: '48 MP + 8 MP (Ultrawide) + 5 MP (Macro) กล้องหน้า 13 MP',
    memory: 'RAM 8 GB \n ROM 128 GB',
    bettery: '5,000 mAh \n ชาร์จไว 25 W',
    alignText: 'left',
    paddingLeftText: '18%',
    isAppliance: false,
    installmentTerm: 6,
    brand: 'samsung',
    hypothesis:
      '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 16,799 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 6 เดือน)) โดนมีงวดดาวน์งวดแรกจำนวน 3,659 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 2,190 บาทต่อเดือน ผ่อนนาน 6 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
    price: '16,799.-',
  },
  {
    title: 'Vivo Y18 LTE',
    urlImage: linkObsImage.service.mobiles.Y18,
    imageHeight: 480,
    imageWidth: 365,
    downPrice: '2,119.-',
    installmentPrice: '930.-',
    screenSize: '6.56 นิ้ว',
    screen: 'IPS LCD 24-bit \n 720 x 1612 พิกเซล',
    camera: '50 MP + 0.08MP (Auxiliary lens) \n กล้องหน้า 8 MP',
    memory: 'RAM 8 GB \n ROM 128 GB',
    bettery: '5,000 mAh \n ชาร์จไว 15 W',
    alignText: 'left',
    paddingLeftText: '18%',
    isAppliance: false,
    installmentTerm: 6,
    brand: 'vivo',
    hypothesis:
      '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 7,699 บาท (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 6 เดือน)) โดยมีงวดดาวน์งวดแรกจำนวน 2,119 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 930 บาทต่อเดือน ผ่อนนาน 6 เดือน ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท กสิกร คาราบาว จำกัด กำหนด',
    price: '7,699.-',
  },
];

import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import BoxMobile from '../commons/ui/box-mobile';

import { useState } from 'react';
import { useAppSelector } from '../../store/store';
import _ from 'lodash';
import SearchBranchesListMobile from './search-branches-list-mobile';
import FilterPlatform from './button-platform';
import FilterProvince from './autocomplete-province';
import FilterDistrict from './autocomplete-district';
import LoadingModal from '../commons/ui/loading-modal';
import { SearchBranches } from '.';
import FilterProductType from './select-product-type';
import FilterRegion from './autocomplete-region';
import { useLocation } from 'react-router-dom';
import { platform } from '../../utils/enum/search-branches-enum';
import { content } from '../../contentData/branches';
import { linkObsImage } from '../../contentData/image-obs-link';

interface Props {
  landscape: boolean;
}

const btnStyle = {
  fontWeight: 700,
  color: '#FFFFFF',
  border: '2px solid #0d7f42',
  background: '#0d7f42',
  borderRadius: '20px',
  '&:hover': {
    backgroundColor: '#51977c',
    borderColor: '#51977c',
  },
};

export default function SearchBranchesMobile({ landscape }: Props) {
  const { state } = useLocation();

  const saveReadCSVMerchantList = useAppSelector((state) => state.SaveReadCSVMerchantList.merchantList);
  const regionListOptionAll = useAppSelector((state) => state.regionList.regionList.all);
  const regionListOptionCJ = useAppSelector((state) => state.regionList.regionList.cj);
  const regionListOptionTD = useAppSelector((state) => state.regionList.regionList.td);

  const provinceListOptionAll = useAppSelector((state) => state.provinceList.provinceList.all);
  const provinceListOptionCJ = useAppSelector((state) => state.provinceList.provinceList.cj);
  const provinceListOptionTD = useAppSelector((state) => state.provinceList.provinceList.td);

  const districtListOptionAll = useAppSelector((state) => state.districtList.districtList.all);
  const districtListOptionCJ = useAppSelector((state) => state.districtList.districtList.cj);
  const districtListOptionTD = useAppSelector((state) => state.districtList.districtList.td);

  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [region, setRegion] = useState('0');
  const [province, setProvince] = useState('0');
  const [district, setDistrict] = useState('0');

  const [merchantName, setMerchantName] = useState('');
  const [keySearch, setKeySearch] = useState('');
  const [platformSelect, setPlatformSelect] = useState(
    state && state.platform ? state.platform : content.fillterMode.platform ?? ''
  );
  const [productType, setProductType] = useState('');
  const [regionList, setRegionList] = useState<any>();
  const [loading] = useState(false);

  const [selectProvince, setSelectProvince] = useState('');
  const [selectDistrict, setSelectDistrict] = useState('');
  const [provinceList, setProvinceList] = useState<any>();
  const [districtList, setDistrictList] = useState<any>([]);
  const [merchantList, setmerchantList] = useState<any>();

  const handleSelectPlatform = (value: any) => {
    setPlatformSelect(value);
    setRegion('');
    setProvince('');
    setDistrict('');
    handleSelectItemRegion(null);
    handleSelectItemProvince(null);
    handleSelectItemDistrict(null);
    if (value === '' || value === 'all') {
      setRegionList(regionListOptionAll);
      setProvinceList(provinceListOptionAll);
    } else if (value === platform.CJ) {
      setRegionList(regionListOptionCJ);
      setProvinceList(provinceListOptionCJ);
    } else if (value === platform.TD) {
      setRegionList(regionListOptionTD);
      setProvinceList(provinceListOptionTD);
    }
  };

  const handleSelectItemRegion = (option: any) => {
    handleSelectItemProvince(null);
    handleSelectItemDistrict(null);
    if (option === null) {
      setRegion('');
      setProvinceList(provinceList);
      setDistrictList([]);
    }
    if (option) {
      setRegion(option.regionName);

      let provinceListOptionFilter: any;
      let districtListOptionFilter: any;
      if (platformSelect === platform.CJ) {
        provinceListOptionFilter = _.filter(provinceListOptionCJ, (item: any) => item.regionName === option.regionName);
        districtListOptionFilter = _.filter(districtListOptionCJ, (item: any) => item.regionName === option.regionName);
      } else if (platformSelect === platform.TD) {
        provinceListOptionFilter = _.filter(provinceListOptionTD, (item: any) => item.regionName === option.regionName);
        districtListOptionFilter = _.filter(districtListOptionCJ, (item: any) => item.regionName === option.regionName);
      } else {
        provinceListOptionFilter = _.filter(
          provinceListOptionAll,
          (item: any) => item.regionName === option.regionName
        );
        districtListOptionFilter = _.filter(
          districtListOptionAll,
          (item: any) => item.regionName === option.regionName
        );
      }
      setProvinceList(provinceListOptionFilter);
    }
  };

  const handleSelectItemProvince = async (option: any) => {
    handleSelectItemDistrict(null);
    if (option === null) {
      setProvince('');
      setDistrictList([]);
    }
    if (option) {
      setSelectProvince(option);
      setProvince(option.provinceName);

      let districtListOptionFilter: any;
      if (platformSelect === platform.CJ) {
        districtListOptionFilter = _.filter(
          districtListOptionCJ,
          (item: any) => item.provinceName === option.provinceName
        );
      } else if (platformSelect === platform.TD) {
        districtListOptionFilter = _.filter(
          districtListOptionTD,
          (item: any) => item.provinceName === option.provinceName
        );
      } else {
        districtListOptionFilter = _.filter(
          districtListOptionAll,
          (item: any) => item.provinceName === option.provinceName
        );
      }
      setDistrictList(districtListOptionFilter);
    }
  };

  const handleChangeKeySearch = (event: any) => {
    const textVal = event.target.value;
    if (textVal) {
      setMerchantName(textVal);
    } else {
      setMerchantName('');
    }
  };
  const handleSelectItemDistrict = (option: any) => {
    if (option === null) setDistrict('');
    if (option) setDistrict(option.districtName);
  };

  const handleSelectProductType = (option: any) => {
    if (option === '0') setProductType('');
    if (option) setProductType(option);
  };

  const handleSearch = () => {
    setOpenLoadingModal(true);
    let merchantListTemp: any = saveReadCSVMerchantList;

    const payload = {
      platform: platformSelect,
      productType: productType,
      merchantName: merchantName,
      region: region,
      province: province,
      district: district,
    };
    setmerchantList(SearchBranches(merchantListTemp, payload));

    setTimeout(() => {
      setOpenLoadingModal(false);
    }, 300);
  };

  return (
    <>
      <BoxMobile>
        <Typography component="div" variant="h4" sx={{ color: '#0E7F42', fontWeight: 700, mt: 4 }}>
          สาขาและร้านค้าให้บริการ
        </Typography>
        <Box>
          <img src={linkObsImage.searchBranches.bannerMobile} width={'100%'} />
        </Box>
        {content.fillterMode.isFillter && (
          <Box mt={2}>
            <FilterPlatform defaultPlatform={platformSelect} onClick={(value: any) => handleSelectPlatform(value)} />
          </Box>
        )}
        <Box mt={4}>
          <FilterProductType onComplete={handleSelectProductType} />
        </Box>
        <Box mt={4}>
          <TextField
            id="txtKey"
            name="key"
            size="small"
            value={merchantName || ''}
            onChange={handleChangeKeySearch}
            fullWidth
            placeholder="ค้นหาสาขา/ร้านค้า"
            autoComplete="off"
            variant="standard"
            sx={{ borderBottom: '1px solid #6D6D6D' }}
          />
        </Box>
        <Box mt={4}>
          <FilterRegion regionListOption={regionList ?? regionListOptionAll} onComplete={handleSelectItemRegion} />
        </Box>
        <Box mt={4}>
          <FilterProvince
            provinceListOption={provinceList ?? provinceListOptionAll}
            onComplete={handleSelectItemProvince}
          />
        </Box>
        <Box mt={4}>
          <FilterDistrict districtList={districtList} clear={false} onComplete={handleSelectItemDistrict} />
        </Box>

        <Box mt={4} width={'100%'}>
          <Button
            variant="contained"
            color="success"
            sx={{ ...btnStyle, marginTop: '5px' }}
            onClick={handleSearch}
            fullWidth>
            ค้นหา
          </Button>
        </Box>
        <Box mt={4} pb={2}>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 1 }}>
            <Grid item xs={12}>
              {merchantList && <SearchBranchesListMobile list={merchantList || []} />}
            </Grid>
          </Grid>
        </Box>
      </BoxMobile>
      <LoadingModal open={openLoadingModal} />
    </>
  );
}

import { Grid, Typography } from '@mui/material';
import { content } from '../../../contentData/kbank-campaign';
import { linkObsImage } from '../../../contentData/image-obs-link';

export interface Props {
  skuContent: any;
}

export default function CarousalDetailMobile({ skuContent }: Props) {
  return (
    <>
      <Grid container pr={2}>
        <Grid xs={5} display={'flex'} justifyContent={'center'} pl={2} pr={2} mt={2}>
          <img src={skuContent.urlImage} width={'100%'} style={{ maxWidth: '120px', maxHeight: '150px' }} />
        </Grid>
        <Grid xs={7} p={1} display={'flex'} alignItems={'center'}>
          <Grid container>
            <Grid xs={12}>
              <Typography component="div" variant="subtitle1" sx={{ fontWeight: 700, fontSize: 24 }}>
                {skuContent.title}
              </Typography>
            </Grid>
            <Grid xs={8}>
              <Typography component="div" sx={{ color: '#626262', fontWeight: 700 }}>
                {content.skuContent.price}
                {'  '}
                {skuContent.price}
              </Typography>
            </Grid>
            <Grid xs={8}>
              <Typography component="div" sx={{ color: '#263238', fontWeight: 700 }}>
                {content.skuContent.down}
              </Typography>
              <Typography component="div" sx={{ color: '#6D6D6D', fontWeight: 700, fontSize: 12 }}>
                {content.skuContent.downText}
              </Typography>
            </Grid>
            <Grid xs={4}>
              <Typography component="div" sx={{ color: '#F54949', fontSize: 20, fontWeight: 700 }}>
                {skuContent.downPrice}
              </Typography>
            </Grid>
            <Grid xs={8}>
              <Typography component="div" sx={{ color: '#263238', fontWeight: 700 }}>
                {content.skuContent.installmentsText}
              </Typography>
              <Typography component="div" sx={{ color: '#6D6D6D', fontWeight: 700, fontSize: 12 }}>
                {content.skuContent.installmentsTerm} {content.skuContent.interrestRate0}
              </Typography>
            </Grid>
            <Grid xs={4}>
              <Typography component="div" sx={{ color: '#F54949', fontSize: 20, fontWeight: 700 }}>
                {skuContent.installmentPrice}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={3} pl={2} pr={2}>
        <Grid xs={1.5}>
          <img src={linkObsImage.service.icon.iconPhone} width="35" style={{ marginBottom: '-1.7em' }} />
        </Grid>
        <Grid xs={4}>
          <Typography>
            {content.skuContent.screenSize}: {skuContent.screenSize}
          </Typography>
          <Typography>
            {content.skuContent.screen}: {skuContent.screen}
          </Typography>
        </Grid>
        <Grid xs={0.5}></Grid>
        <Grid xs={1.5}>
          <img src={linkObsImage.service.icon.iconScreen} width="30" style={{ marginBottom: '-1.5em' }} />
        </Grid>
        <Grid xs={4} sx={{ whiteSpace: 'pre-line' }}>
          <Typography>{skuContent.camera}</Typography>
        </Grid>

        <Grid xs={1.5} mt={2}>
          <img
            src={linkObsImage.service.icon.iconRam}
            width="30"
            style={{ marginBottom: '-1.4em', marginLeft: '0.2em' }}
          />
        </Grid>
        <Grid xs={4} mt={2} sx={{ whiteSpace: 'pre-line' }}>
          <Typography>{skuContent.memory}</Typography>
        </Grid>
        <Grid xs={0.5}></Grid>
        <Grid xs={1.5} mt={2}>
          <img src={linkObsImage.service.icon.iconBaterry} width="30" style={{ marginBottom: '-1.4em' }} />
        </Grid>
        <Grid xs={4} mt={2} sx={{ whiteSpace: 'pre-line' }}>
          <Typography>{skuContent.bettery}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Typography
          pl={2}
          component="div"
          sx={{ color: '#6D6D6D', mt: 2, fontSize: 12, fontWeight: 400, textAlign: 'left' }}>
          {content.skuContent.hypothesis}
        </Typography>
        <Typography pl={2} component="div" sx={{ color: '#6D6D6D', fontSize: 12, fontWeight: 400, textAlign: 'left' }}>
          {skuContent.hypothesis}
        </Typography>
      </Grid>
    </>
  );
}

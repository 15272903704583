import { Box, Typography } from '@mui/material';
import { contactUSContent } from '../../contentData/contact-us';
import BoxMobile from '../commons/ui/box-mobile';
import { linkObsImage } from '../../contentData/image-obs-link';
interface Props {
  landscape: boolean;
}

export default function ContactContentMobile({ landscape }: Props) {
  return (
    <>
      <BoxMobile>
        <Typography component="div" variant="h4" sx={{ color: '#0E7F42', fontWeight: 700, mt: 4 }}>
          {contactUSContent.header}
        </Typography>

        <Box mt={3}>
          <Typography component="div" variant="body1">
            {contactUSContent.address.name}
          </Typography>

          <Typography component="div" variant="body1">
            {contactUSContent.address.address1}
          </Typography>

          <Typography component="div" variant="body1">
            {contactUSContent.address.address2} {contactUSContent.address.subDistrict}
          </Typography>

          <Typography component="div" variant="body1">
            {contactUSContent.address.district} {contactUSContent.address.province} {contactUSContent.address.zipCode}
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography component="div" variant="body1" sx={{ fontWeight: 700 }}>
            {contactUSContent.office.title}
          </Typography>

          <Typography component="div" variant="body1">
            {contactUSContent.office.day}
          </Typography>

          <Typography component="div" variant="body1">
            {contactUSContent.office.time}
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography component="div" variant="body1" sx={{ fontWeight: 700 }}>
            {contactUSContent.customerServiceCenter.title}
          </Typography>

          <Typography component="div" variant="body1">
            {contactUSContent.customerServiceCenter.day}
          </Typography>

          <Typography component="div" variant="body1">
            {contactUSContent.customerServiceCenter.time}
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography component="span" variant="body1" sx={{ fontWeight: 700 }}>
            {contactUSContent.email.title}{' '}
          </Typography>
          <a
            target="_blank"
            href="mailto:info@kasikorncarabao.com"
            style={{ textDecoration: 'none', color: '#030303' }}>
            {contactUSContent.email.email}
          </a>
        </Box>
        <Box mt={1}>
          <Typography component="span" variant="body1" sx={{ fontWeight: 700 }}>
            {contactUSContent.line.title}{' '}
          </Typography>
          <a target="_blank" href="https://line.me/R/ti/p/@kbao" style={{ textDecoration: 'none', color: '#030303' }}>
            {contactUSContent.line.line}
          </a>
        </Box>

        <Box mt={2}>
          <img src={linkObsImage.contact.imgLineKbao} alt="logo" width="180" />
        </Box>

        <Box mt={3}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d911.2187868173955!2d100.5286269617743!3d13.725857912860278!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDQzJzMyLjYiTiAxMDDCsDMxJzQ0LjciRQ!5e0!3m2!1sth!2sth!4v1702454023722!5m2!1sth!2sth"
            width="100%"
            height="300"
            loading="lazy"
            style={{ border: 'none', maxWidth: 800 }}></iframe>
        </Box>
      </BoxMobile>
    </>
  );
}

import { Box, Typography } from '@mui/material';
import BoxMobile from '../../commons/ui/box-mobile';
import FAQContent from '../../faqs/faq-content';
import LargeheroContent from './largehero-content';
import BestSellingContent from './best-selling-content';
import StepContent from './step-content';
import { useNavigate } from 'react-router-dom';
import ButtonContent from './button-content';
import { TypeScreen } from '../../../utils/enum/responsive-enum';
import { content } from '../../../contentData/kbank-campaign';
import { platform } from '../../../utils/enum/search-branches-enum';
import { linkObsImage } from '../../../contentData/image-obs-link';

export default function HomePageContentMobile() {
  const navigate = useNavigate();

  return (
    <>
      <BoxMobile>
        <Typography component="div" variant="h6" sx={{ fontWeight: 700, mb: -1 }}>
          {content.homepage.lineBK.title}
        </Typography>
      </BoxMobile>
      <Box
        mb={2}
        onClick={() => {
          navigate('/services', { state: { productSamsung: true } });
        }}>
        <LargeheroContent largehero={linkObsImage.campaignKbank.largehero.largeheroMobile} type={TypeScreen.MOBILE} />
      </Box>
      <Box mb={2}>
        <ButtonContent type={TypeScreen.MOBILE} />
      </Box>

      <Box p={3} pb={1}>
        <Typography component="div" variant="h6" sx={{ fontWeight: 700 }}>
          {content.homepage.bestSelling.title}
        </Typography>
      </Box>
      <Box mb={2}>
        <BestSellingContent type={TypeScreen.MOBILE} />
      </Box>

      <Box p={3} pt={0}>
        <Typography component="div" variant="h6" sx={{ fontWeight: 700, mb: 2 }}>
          {content.homepage.step.title}
        </Typography>
        <StepContent type={TypeScreen.MOBILE} />
      </Box>

      <Box sx={{ margin: 'auto' }}>
        <img
          src={linkObsImage.campaign.banner.bannerServiceMobile}
          width="100%"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigate('/branches-search', { state: { platform: platform.CJ } });
          }}
        />
      </Box>

      <Box p={3}>
        <Typography component="div" variant="h6" sx={{ fontWeight: 700, mb: 2 }}>
          {content.homepage.faq.title}
        </Typography>

        <Box mb={2}>
          <FAQContent />
        </Box>
      </Box>

      <Box mb={2}>
        <ButtonContent type={TypeScreen.MOBILE} />
      </Box>
    </>
  );
}

import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../store/store';
import { useEffect } from 'react';
import Papa from 'papaparse';
import { saveReadCSVMerchantList } from '../store/slices/save-read-csv-file-slice';
import { saveDistrictList } from '../store/slices/district-list-slice';
import { saveProvinceList } from '../store/slices/province-list-slice';
import SearchBranchesContent from '../components/search-branches/search-branches-content';
import _ from 'lodash';
import { saveRegionList } from '../store/slices/region-list-slice';
import { platform } from '../utils/enum/search-branches-enum';
import { uniqRegionList, uniqProvinceList, uniqDistrictList } from '../components/search-branches/index';
import { trimObject } from '../utils/utils';

export default function BranchesSearch() {
  const dispatch = useAppDispatch();

  const handleSaveCsvMerchantList = async (list: any) => {
    await dispatch(saveReadCSVMerchantList(list));

    const payloadRegionList = {
      all: uniqRegionList(list, 'all'),
      cj: uniqRegionList(list, platform.CJ),
      td: uniqRegionList(list, platform.TD),
    };
    await dispatch(saveRegionList(payloadRegionList));

    const payloadProvinceList = {
      all: uniqProvinceList(list, 'all'),
      cj: uniqProvinceList(list, platform.CJ),
      td: uniqProvinceList(list, platform.TD),
    };
    await dispatch(saveProvinceList(payloadProvinceList));

    const payloadDistrictList = {
      all: uniqDistrictList(list, 'all'),
      cj: uniqDistrictList(list, platform.CJ),
      td: uniqDistrictList(list, platform.TD),
    };
    await dispatch(saveDistrictList(payloadDistrictList));
  };

  let currentPath = window.location.href;
  const getData = async () => {
    currentPath = currentPath.substring(0, currentPath.lastIndexOf('/'));
    const branchesCommonURL = currentPath;
    await Papa.parse(`${branchesCommonURL}/cdn/branches/merchant.csv`, {
      header: true,
      download: true,
      skipEmptyLines: true,
      delimiter: ',',
      complete: (results: any) => {
        handleSaveCsvMerchantList(trimObject(results.data));
      },
      error: (err: Error) => {
        console.log(err);
      },
    });
  };
  const ListMerchants = useAppSelector((state) => state.SaveReadCSVMerchantList);

  useEffect(() => {
    if (ListMerchants.merchantList.length == 0) {
      getData();
    }
  }, []);
  return (
    <>
      <Box>
        <SearchBranchesContent />
      </Box>
    </>
  );
}

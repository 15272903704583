import { Done } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { serviceContent } from '../../contentData/service';

import {
  daikinMaxInverter,
  mitsubishiFlatDesign,
  oppoA184G,
  oppoA3Pro5G,
  oppoA3x,
  oppoA584G,
  oppoA784G,
  oppoA985G,
  samsungGalaxyA05,
  samsungGalaxyA05s,
  samsungGalaxyA244G,
  samsungGalaxyA345G,
  tclNewMiracle,
  toshibaTopload,
  vivoY18,
} from './content-data-sku';
import FAQBoxContent from './faq-box-content';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import PhoneContentBoxMobile from './phone-content-box-m';
import ApplianceContentBoxMobile from './appliance-content-box-m';
import { linkObsImage } from '../../contentData/image-obs-link';

export default function ServiceContentMobile() {
  const { state } = useLocation();
  useEffect(() => {
    if (state) {
      let element: any = '';
      if (state.faq) {
        element = document.getElementById('box-faq');
      } else if (state.productSamsung) {
        element = document.getElementById('box-product-samsung');
      } else if (state.productVivo) {
        element = document.getElementById('box-product-vivo');
      }
      element = element.getBoundingClientRect();
      const heightBox = element.top + window.scrollY || window.innerHeight;
      window.scrollTo({ top: heightBox, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [state]);

  return (
    <>
      <Box>
        <Box m="8%" mt="20%">
          <Typography component="div" variant="h3" sx={{ color: '#0E7F42', fontWeight: 700 }}>
            {serviceContent.header}
          </Typography>

          <Typography component="div" variant="h6" sx={{ color: '#0E7F42', mt: 1 }}>
            {serviceContent.title}
          </Typography>

          <Box mt={2}>
            <img src={linkObsImage.service.logo.cjMore} width="80" style={{ borderRadius: 10, marginRight: 15 }} />
            <img src={linkObsImage.service.logo.aHome} width="80" style={{ borderRadius: 10 }} />
          </Box>

          <Box mt={4}>
            <Typography component="div" variant="h6" sx={{ color: '#0E7F42', fontWeight: 700 }}>
              <Box display={'flex'} justifyContent={'start'}>
                <Box>
                  <Done sx={{ fontSize: 40, color: '#0E7F42', marginBottom: -1 }} />
                </Box>
                <Box>
                  <span style={{ fontSize: 20 }}>{serviceContent.checkedVal1}</span>
                </Box>
              </Box>
            </Typography>

            <Typography component="div" variant="h6" sx={{ color: '#0E7F42', fontWeight: 700, mt: 2 }}>
              <Done sx={{ fontSize: 40, color: '#0E7F42', marginBottom: -1 }} />
              <span style={{ fontSize: 20 }}>{serviceContent.checkedVal2}</span>
            </Typography>

            <Typography component="div" variant="h6" sx={{ color: '#0E7F42', fontWeight: 700, mt: 2 }}>
              <Done sx={{ fontSize: 40, color: '#0E7F42', marginBottom: -1 }} />
              <span style={{ fontSize: 20 }}>{serviceContent.checkedVal3}</span>
            </Typography>

            <Typography component="div" variant="h6" sx={{ color: '#0E7F42', fontWeight: 700, mt: 2 }}>
              <Box display={'flex'} justifyContent={'start'}>
                <Box>
                  <Done sx={{ fontSize: 40, color: '#0E7F42', marginBottom: -1 }} />
                </Box>
                <Box>
                  <span style={{ fontSize: 20 }}>{serviceContent.checkedVal4}</span>
                </Box>
              </Box>
            </Typography>

            <Typography component="div" variant="h6" sx={{ color: '#0E7F42', fontWeight: 700, mt: 2 }}>
              <Done sx={{ fontSize: 40, color: '#0E7F42', marginBottom: -1 }} />
              <span style={{ fontSize: 20 }}>{serviceContent.checkedVal5}</span>
            </Typography>
          </Box>

          <Box mt={8}>
            <Typography component="div" variant="h5" sx={{ color: '#0E7F42', fontWeight: 700, mb: 2 }}>
              {serviceContent.brandProduct}
            </Typography>

            <Box sx={{ minHeight: 150, backgroundColor: '#e4efea', textAlign: 'center', pb: 2, ml: '-9%', mr: '-9%' }}>
              <Box mt={2}>
                <img
                  src={linkObsImage.service.logo.samsung}
                  style={{ maxWidth: 150, marginRight: '5%', marginBottom: -30 }}
                />
                <img src={linkObsImage.service.logo.haier} style={{ maxWidth: 100 }} />
              </Box>
              <Box mt={2}>
                <img src={linkObsImage.service.logo.mitsubishiElectric} style={{ maxWidth: 130, marginRight: '6%' }} />
                <img src={linkObsImage.service.logo.oppo} style={{ maxWidth: 130 }} />
              </Box>

              <Box mt={2}>
                <img src={linkObsImage.service.logo.daikin} style={{ maxWidth: 130, marginRight: '6%' }} />
                <img src={linkObsImage.service.logo.carrier} style={{ maxWidth: 100 }} />
              </Box>
            </Box>
          </Box>

          <Box mt={8}>
            <Typography component="div" variant="h5" sx={{ color: '#0E7F42', fontWeight: 700 }}>
              {serviceContent.mobileTile}
            </Typography>
            <Box id="box-product-samsung"></Box>
            <PhoneContentBoxMobile skuContent={samsungGalaxyA05} />
            <PhoneContentBoxMobile skuContent={samsungGalaxyA05s} />
            <PhoneContentBoxMobile skuContent={samsungGalaxyA244G} />
            <PhoneContentBoxMobile skuContent={samsungGalaxyA345G} />
            <PhoneContentBoxMobile skuContent={oppoA184G} />
            <PhoneContentBoxMobile skuContent={oppoA584G} />
            <PhoneContentBoxMobile skuContent={oppoA784G} />
            <PhoneContentBoxMobile skuContent={oppoA985G} />
            <PhoneContentBoxMobile skuContent={oppoA3Pro5G} />
            <PhoneContentBoxMobile skuContent={oppoA3x} />
            <Box id="box-product-vivo"></Box>
            <PhoneContentBoxMobile skuContent={vivoY18} />
          </Box>
          <ApplianceContentBoxMobile skuContent={daikinMaxInverter} />
          <ApplianceContentBoxMobile skuContent={tclNewMiracle} />
          <ApplianceContentBoxMobile skuContent={toshibaTopload} />
          <ApplianceContentBoxMobile skuContent={mitsubishiFlatDesign} />
        </Box>
      </Box>

      <Box id="box-faq" m="10%" mt="-4%">
        <FAQBoxContent />
      </Box>
    </>
  );
}

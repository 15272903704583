import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { linkObsImage } from '../../../contentData/image-obs-link';

const properties = {
  prevArrow: (
    <IconButton sx={{ ml: -6 }}>
      <ArrowBackIos sx={{ fontSize: 50, color: '#263238' }} />
    </IconButton>
  ),
  nextArrow: (
    <IconButton sx={{ mr: -7.5 }}>
      <ArrowForwardIos sx={{ fontSize: 50, color: '#263238' }} />
    </IconButton>
  ),
};

const carousalImageStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  height: '350px',
  backgroundPosition: 'center',
};

const images = [linkObsImage.home.banner1, linkObsImage.home.banner2, linkObsImage.home.banner3];
export default function CarousalImage() {
  return (
    <>
      <Box style={{ minWidth: 650 }}>
        <Slide {...properties}>
          {images.map((slideImage, index) => (
            <Box
              id={`id-${index}`}
              style={{
                backgroundImage: `url(${slideImage})`,
                ...carousalImageStyle,
              }}></Box>
          ))}
        </Slide>
      </Box>
    </>
  );
}

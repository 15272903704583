import { Box } from '@mui/material';
import React, { useEffect } from 'react';
// import Frame1 from '../../../assets/images/campaign/category/Frame1.png';
// import Frame2 from '../../../assets/images/campaign/category/Frame2.png';
// import Frame3 from '../../../assets/images/campaign/category/Frame3.png';
import { productType } from '../../../utils/enum/search-branches-enum';
import { linkObsImage } from '../../../contentData/image-obs-link';

export interface Props {
  onChange: (data: any) => void;
}

export default function CategoryContent(props: Props) {
  const boxImageStyle = {
    width: '40%',
    textAlign: 'left',
  };

  const imageStyle = {
    width: '90%',
    border: '2px solid #FFFFFF',
  };

  const imageSelectStyle = {
    border: '2px solid #0d7f42',
    borderRadius: '14px',
  };

  const [categorys, setCategorys] = React.useState<any>([]);
  useEffect(() => {
    const categoryList = [
      {
        id: 1,
        name: productType.MOBILE,
        image: linkObsImage.campaign.category.frame1,
        select: false,
      },
      {
        id: 2,
        name: productType.FRIDGE,
        image: linkObsImage.campaign.category.frame1,
        select: false,
      },
      {
        id: 3,
        name: productType.AIR_CONDITIONER,
        image: linkObsImage.campaign.category.frame1,
        select: false,
      },
    ];

    setCategorys(categoryList);
  }, []);

  const handleChangeCategory = (value: string) => {
    let category = categorys.map((category: any) =>
      category.name === value ? { ...category, select: !category.select } : category
    );
    setCategorys(category);

    category = category.filter((e: any) => e.select === true);
    return props.onChange(category);
  };

  return (
    <>
      <Box sx={{ display: 'flex', mt: 4 }}>
        {categorys.map((category: any, index: number) => (
          <Box sx={boxImageStyle}>
            <img
              id={`id-${index}`}
              src={category.image}
              onClick={() => {
                handleChangeCategory(category.name);
              }}
              style={{
                ...imageStyle,
                ...(category.select && {
                  ...imageSelectStyle,
                }),
              }}
            />
          </Box>
        ))}
      </Box>
    </>
  );
}

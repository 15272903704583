import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import PriceComponent from './price-component';
import { content } from './content-data-sku';
import { linkObsImage } from '../../contentData/image-obs-link';

export interface props {
  skuContent: any;
}
const cardStyle = {
  borderRadius: '0px, 0px, 8px, 8px ',
  background: '#F9F8F8',
};

export default function PhoneContentBox({ skuContent }: props) {
  return (
    <>
      <Card sx={{ marginRight: '1em' }}>
        <CardContent sx={{ ...cardStyle }}>
          <img
            src={skuContent.urlImage}
            style={{ maxWidth: '250px', height: '240px', marginTop: '24px', marginBottom: '24px' }}
          />
        </CardContent>
        <Box sx={{ backgroundColor: '#FFFFFF' }}>
          <Typography
            component="div"
            variant="h6"
            sx={{ fontWeight: 700, pt: 2, textAlign: 'start', marginLeft: '25px' }}>
            {skuContent.title}
          </Typography>

          <PriceComponent skuContent={skuContent} isAppliance={skuContent.isAppliance} />
          <Box sx={{ minHeight: 315 }}>
            <Grid container mt={4}>
              <Grid xs={3} sx={{ textAlign: 'center' }}>
                <img src={linkObsImage.service.icon.iconPhone} width="40" style={{ marginBottom: '-2em' }} />
              </Grid>
              <Grid xs={8} sx={{ textAlign: 'left', height: '85px' }}>
                <Typography>
                  {content.skuContent.screenSize}: <strong>{skuContent.screenSize}</strong>
                </Typography>
                <Typography style={{ height: '48px' }}>
                  {content.skuContent.screen}: <strong>{skuContent.screen}</strong>
                </Typography>
              </Grid>
              <Grid xs={1}></Grid>
            </Grid>

            <Grid container>
              <Grid xs={3} sx={{ textAlign: 'center' }}>
                <img src={linkObsImage.service.icon.iconScreen} width="35" style={{ marginBottom: '-1.5em' }} />
              </Grid>
              <Grid xs={8} sx={{ textAlign: 'left', height: '85px', whiteSpace: 'pre-line' }}>
                <Typography>
                  <strong>{skuContent.camera}</strong>
                </Typography>
              </Grid>
              <Grid xs={1}></Grid>
            </Grid>

            <Grid container>
              <Grid xs={3} sx={{ textAlign: 'center' }}>
                <img src={linkObsImage.service.icon.iconRam} width="40" style={{ marginBottom: '-1.6em' }} />
              </Grid>
              <Grid xs={8} sx={{ textAlign: 'left', minHeight: '85px', whiteSpace: 'pre-line' }}>
                <Typography>
                  <strong>{skuContent.memory}</strong>
                </Typography>
              </Grid>
              <Grid xs={1}></Grid>
            </Grid>

            <Grid container>
              <Grid xs={3} sx={{ textAlign: 'center' }}>
                <img src={linkObsImage.service.icon.iconBaterry} width="35" style={{ marginBottom: '-1.7em' }} />
              </Grid>
              <Grid
                xs={8}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'left',
                  minHeight: '50px',
                  whiteSpace: 'pre-line',
                }}>
                <Typography>
                  <strong>{skuContent.bettery}</strong>
                </Typography>
              </Grid>
              <Grid xs={1}></Grid>
            </Grid>

            <Grid container mt={1}>
              <Typography
                pl={3}
                component="div"
                sx={{ color: '#6D6D6D', mt: 2, fontSize: 12, fontWeight: 400, textAlign: 'left' }}>
                {content.skuContent.hypothesis}
              </Typography>
              <Typography
                pl={3}
                pr={3}
                pb={3}
                component="div"
                sx={{ color: '#6D6D6D', fontSize: 12, fontWeight: 400, textAlign: 'left' }}>
                {skuContent.hypothesis}
              </Typography>
            </Grid>
          </Box>
        </Box>
      </Card>
    </>
  );
}
